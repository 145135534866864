import { Button } from "@/app/components/ui/button";
import type { States } from "@/app/data//data";
import {
  type SearchQuerySimple,
  capitalize,
  toUrlFilename,
} from "@/app/lib/search-utils";
import * as Collapsible from "@radix-ui/react-collapsible";
import { useNavigate } from "@remix-run/react";
import { BookHeartIcon, MapIcon, Search, StethoscopeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { tv } from "tailwind-variants";
import { Combobox } from "./combobox";
import { CombinedCombobox } from "./combobox-combined";
import { Wrapper } from "./wrapper";

export interface ListItem {
  value: string;
  label: string;
}

const searchForm = tv({
  slots: {
    searchBtn: "primary px-2",
    base: "",
  },
  variants: {
    color: {
      primary: {
        base: "bg-primary-600",
        searchBtn: "bg-secondary-500 w-full md:h-14",
      },
      secondary: { base: "bg-white", searchBtn: "bg-primary-600 md:h-14" },
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

function generateSearchSummary(query: SearchQuerySimple | null): string {
  if (!query) return "";

  const { city, state, specialty, healthInsurance, totalResults = 0 } = query;
  const location = [capitalize(city), state?.toUpperCase()]
    .filter(Boolean)
    .join(" - ");
  const specialtyText = capitalize(specialty) || "médicos";
  const insuranceText = healthInsurance
    ? `que atendem ${capitalize(healthInsurance)}`
    : "";

  const parts = [
    location && `Em ${location},`,
    `atualmente estão disponíveis ${totalResults}`,
    specialtyText,
    insuranceText,
    "altamente treinados e prontos para fornecer um atendimento de primeira qualidade.",
  ].filter(Boolean);

  return parts.join(" ");
}

export function SearchForm({
  color,
  healthInsurances,
  isOpen = false,
  specialties,
  stateAndCities,
  showFilter = false,
  query = null,
}: {
  color?: "primary" | "secondary";
  healthInsurances: ListItem[];
  isOpen?: boolean;
  specialties: ListItem[];
  stateAndCities: States[];
  showFilter?: boolean;
  query: SearchQuerySimple | null;
}): JSX.Element {
  const navigate = useNavigate();
  const [selectedValueSpec, setSelectedValueSpec] = useState<
    string | undefined
  >(undefined);
  const [selectedValueHS, setSelectedValueHS] = useState<string | undefined>(
    undefined,
  );
  const [selectedValueState, setSelectedValueState] = useState<
    string | undefined
  >(undefined);
  const [selectedValueCity, setSelectedValueCity] = useState<
    string | undefined
  >(undefined);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (query?.healthInsurance) {
      setSelectedValueHS(capitalize(query.healthInsurance));
    }
    if (query?.city) {
      setSelectedValueCity(capitalize(query.city));
    }
    if (query?.state) {
      const uf = stateAndCities.filter(
        (state) => state.uf === query.state?.toUpperCase(),
      )[0]?.name;
      if (uf) {
        setSelectedValueState(uf);
      }
    }
    if (query?.specialty) {
      setSelectedValueSpec(capitalize(query.specialty));
    }
  }, [query, stateAndCities]);

  const handleButtonClick = (): void => {
    let uf = undefined;
    if (selectedValueState) {
      uf =
        stateAndCities.filter((state) => state.name === selectedValueState)[0]
          ?.uf || "";
    }

    let selectedValues = [
      selectedValueSpec,
      uf,
      selectedValueCity,
      selectedValueHS,
    ]
      .filter((v) => v !== undefined && v !== "")
      .map((value) => toUrlFilename(value as string))
      .join("/");
    if (selectedValues === "") {
      selectedValues = "clinico-geral";
    }

    navigate(`/${selectedValues}`);
  };

  const css = searchForm({ color });

  const content = (
    <section
      aria-label="Buscador de profissionais de saúde"
      className={css.base()}
    >
      <Wrapper className="flex flex-col gap-4 pb-10 md:flex-row md:items-end">
        <div className="md:w-[29%]">
          <Combobox
            color={color}
            label="Especialidade"
            LabelIcon={StethoscopeIcon}
            listItems={specialties}
            onChange={setSelectedValueSpec}
            value={selectedValueSpec}
          />
        </div>
        <div className="md:w-[29%]">
          <Combobox
            color={color}
            label="Plano de Saúde"
            LabelIcon={BookHeartIcon}
            listItems={healthInsurances}
            onChange={setSelectedValueHS}
            value={selectedValueHS}
          />
        </div>
        <div className="md:w-[32%]">
          <CombinedCombobox
            color={color}
            label="Localização"
            LabelIcon={MapIcon}
            list={stateAndCities}
            firstValue={selectedValueState}
            secondValue={selectedValueCity}
            onChangeFirst={setSelectedValueState}
            onChangeSecond={setSelectedValueCity}
          />
        </div>
        <div className="md:w-[10%]">
          <Button
            className={css.searchBtn()}
            variant="secondary"
            size="default"
            type="button"
            onClick={handleButtonClick}
          >
            <Search className="mr-2 h-5 w-5" /> Buscar
          </Button>
        </div>
      </Wrapper>
    </section>
  );

  const filter = showFilter ? (
    <Wrapper>
      <Collapsible.Root
        className="CollapsibleRoot"
        open={open}
        onOpenChange={setOpen}
      >
        <div className="flex flex-col py-6 gap-2">
          <h1 className="text-xl font-semibold text-neutral-900">
            {generateSearchSummary(query)}
          </h1>
          <Collapsible.Trigger asChild>
            <Button variant="defaultLight"> Filtros</Button>
          </Collapsible.Trigger>
        </div>
        <Collapsible.Content className="data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
          {content}
        </Collapsible.Content>
      </Collapsible.Root>
    </Wrapper>
  ) : null;

  return <>{showFilter ? filter : content}</>;
}
